import React, { lazy, Suspense } from 'react';
import './index.css';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import DisableDevtool from 'disable-devtool';
import { Provider } from 'react-redux';
import { store, persistor } from './reduxRTK/Store';
import { PersistGate } from 'redux-persist/integration/react';
import LoadingScreen from './reusable/LoadingScreen/LoadingScreen';
const App = lazy(() => import('./App'));

if (
  window.location.origin === "https://dev-react.nxcar.in" ||
  window.location.origin === "https://dev.nxcar.in" ||
  window.location.origin === "http://localhost:3000" ||
  window.location.origin === "http://dev.nxcar.in") {
  axios.defaults.baseURL = process.env.REACT_APP_BACKEND_DEV_URL;
} else if (
  window.location.origin === "https://www.nxcar.in" ||
  window.location.origin === "http://www.nxcar.in" ||
  window.location.origin === "https://nxcar.in" ||
  window.location.origin === "http://nxcar.in"
) {
  axios.defaults.baseURL = process.env.REACT_APP_BACKEND_PROD_URL;
  // DisableDevtool();
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <BrowserRouter>
        <Suspense fallback={<><LoadingScreen /></>}>
          <App />
        </Suspense>
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={true}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </BrowserRouter>
    </PersistGate>
  </Provider>,
);

reportWebVitals();
