import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoggedIn: false,
  token: null,
  user_id: null,
  mobile: null,
  username: null,
  loginTime: null,
  is_dealer: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action) => {
      const { token, user_id, mobile, username, is_dealer } = action.payload;
      state.isLoggedIn = true;
      state.token = token;
      state.username = username;
      state.user_id = user_id;
      state.mobile = mobile;
      state.is_dealer = is_dealer;
      state.loginTime = Date.now(); // Save login time
    },
    logout: (state) => {
      state.isLoggedIn = false;
      state.token = null;
      state.user_id = null;
      state.username = null;
      state.mobile = null;
      state.is_dealer = null;
      state.loginTime = null; // Clear login time
    },
    checkLogin: (state) => {
      const currentTime = Date.now();
      const sevenDays = 24 * 60 * 60 * 1000 * 7; // 7 days

      if (state.token && state.loginTime) {
        // Check if 1 minute has passed since login
        if (currentTime - state.loginTime > sevenDays) {
          state.isLoggedIn = false;
          state.token = null;
          state.user_id = null;
          state.username = null;
          state.mobile = null;
          state.is_dealer = null;
          state.loginTime = null;
        } else {
          state.isLoggedIn = true;
        }
      } else {
        state.isLoggedIn = false;
      }
    },
  },
});

export const { login, logout, checkLogin } = authSlice.actions;
export default authSlice.reducer;